import type { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import * as R from 'ramda';
import { catchError, filter, map, of, Subject, tap, type Observable, type Subscription } from 'rxjs';

import {
  getOffsetPagingOptionsParams,
  type OffsetPagingOptions,
  type OffsetPagingWrapper,
} from '@clover/core/helpers/paging';
import { ccPreventDuplicateSubscriptions } from '@clover/core/helpers/prevent-duplicate-subscriptions';
import { CdkPortalService } from '@clover/core/services/cdk-portal.service';
import { HttpService } from '@clover/core/services/http.service';
import { ToastType } from '@design/overlays/toast/toast';

import {
  FilterableWorkflowStatus,
  WorkflowPreview,
  WorkflowStatus,
  WorkflowType,
  type Workflow,
  type WorkflowAccessType,
  type WorkflowDetails,
} from './workflows-state.model';
import { PatchWorkflow, RemoveWorkflow } from './workflows.actions';

interface WorkflowResponse {
  id: number;
  name: string;
  type: WorkflowType;
  description: string;
  data: string;
  folderId: number;
  status: WorkflowStatus;
  thumbnailImage: string;
  access: {
    type: WorkflowAccessType;
    teamIds: number[];
  };
}

interface WorkflowDetailsResponse {
  id: number;
  name: string;
  type: WorkflowType;
  status: WorkflowStatus;
  version: number;
  folderId: number;
  modifiedAt: string;
  createdAt: string;
  createdBy: {
    id: number;
    firstName: string;
    lastName: string;
    title: string;
    logoUrl: string;
  };
  description: string;
  access: {
    type: WorkflowAccessType;
    teams: {
      id: number;
      name: string;
    }[];
  };
}

interface WorkflowPreviewResponse {
  id: number;
  name: string;
  type: WorkflowType;
  status: WorkflowStatus;
  thumbnailImage: string;
  version: number;
  access: {
    type: WorkflowAccessType;
    teamIds: number[];
  };
}

export enum WorkflowErrorCode {
  InUse = 'workflow_in_use',
}

function mapWorkflow(w: WorkflowResponse): Workflow {
  return {
    id: w.id,
    name: w.name,
    description: w.description,
    version: 1, // TODO (Oleksandr D.): fix this to use version from BE
    graphJSON: w.data,
    type: w.type,
    status: w.status,
    previewImage: w.thumbnailImage,
    folderId: w.folderId,
    access: {
      type: w.access.type,
      teams: (w.access.teamIds || []).map((teamId) => ({ id: teamId })),
    },
  };
}

function mapWorkflowDetails(w: WorkflowDetailsResponse): WorkflowDetails {
  return {
    id: w.id,
    name: w.name,
    description: w.description,
    version: w.version,
    type: w.type,
    status: w.status,
    folderId: w.folderId,
    access: {
      type: w.access.type,
      teams: w.access.teams.map((team) => ({ id: team.id, name: team.name })),
    },
    modifiedAt: w.modifiedAt,
    createdAt: w.createdAt,
    createdBy: {
      id: w.createdBy.id,
      firstName: w.createdBy.firstName,
      lastName: w.createdBy.lastName,
      avatarUrl: w.createdBy.logoUrl,
      title: w.createdBy.title,
    },
  };
}

function mapWorkflowPreview(w: WorkflowPreviewResponse): WorkflowPreview {
  return {
    id: w.id,
    name: w.name,
    version: w.version,
    type: w.type,
    status: w.status,
    previewImage: w.thumbnailImage,
    access: {
      type: w.access.type,
      teams: (w.access.teamIds || []).map((teamId) => ({ id: teamId })),
    },
  };
}

export type WorkflowsSortingProperty = 'name' | 'type' | 'status';

@Injectable({
  providedIn: 'root',
})
export class WorkflowsService {
  private readonly http = inject(HttpService);
  private readonly router = inject(Router);
  private readonly serializer = inject(UrlSerializer);

  private readonly portalService = inject(CdkPortalService);
  private readonly store = inject(Store);

  private _workflowUpdated$ = new Subject<Workflow>();

  subscribeToWorkflowUpdates(id: number): Observable<Workflow> {
    return this._workflowUpdated$.asObservable().pipe(filter((workflow) => workflow.id === id));
  }

  getWorkflow(id: number): Observable<Workflow> {
    const urlTree = this.router.createUrlTree(['api', 'companies', 'my', 'workflows', id]);

    const path = this.serializer.serialize(urlTree);
    return this.http.getV2<WorkflowResponse>(path).pipe(map(mapWorkflow));
  }

  getWorkflowDetails(id: number): Observable<WorkflowDetails> {
    const urlTree = this.router.createUrlTree(['api', 'companies', 'my', 'workflows', id, 'details']);

    const path = this.serializer.serialize(urlTree);
    return this.http.getV2<WorkflowDetailsResponse>(path).pipe(map(mapWorkflowDetails));
  }

  searchWorkflows(
    query: string,
    options:
      | {
          status?: FilterableWorkflowStatus;
          folderId?: number;
        }
      | undefined = {},
    pagingOptions?: OffsetPagingOptions<WorkflowsSortingProperty>,
  ): Observable<OffsetPagingWrapper<WorkflowPreview>> {
    const urlTree = this.router.createUrlTree(['api', 'companies', 'my', 'workflows', 'search'], {
      queryParams: {
        mode: 'All',
        query: query || undefined,
        status: options.status === FilterableWorkflowStatus.All ? undefined : options.status,
        folderId: options.folderId,
        ...getOffsetPagingOptionsParams(pagingOptions),
      },
    });

    const path = this.serializer.serialize(urlTree);
    return this.http.getV2<OffsetPagingWrapper<WorkflowPreviewResponse>>(path).pipe(
      map((response) => ({
        ...response,
        data: response.data.map(mapWorkflowPreview),
      })),
    );
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  publishWorkflow(workflowId: number /* folderId: number */): void {
    this.requestWorkflowUpdate(workflowId);
    this.portalService.presentToast('Workflow published', ToastType.Success);

    // We use legacy functionality for publishing workflows, so this is a placeholder for future implementation
    // TODO (Oleksandr D.): Rewrite workflow publish modal

    // return this.http
    //   .putV2<void>(`api/companies/my/workflows/${workflowId}/publish/${folderId}`)
    //   .pipe(
    //     tap(() => {
    //       this.portalService.presentToast('Workflow published', ToastType.Success);
    //       this.requestWorkflowUpdate(workflowId);
    //       this.store.dispatch(
    //         new PatchWorkflow(
    //           workflowId,
    //           patch({
    //             status: WorkflowStatus.Published,
    //           }),
    //         ),
    //       );
    //     }),
    //     catchError((error) => {
    //       this.portalService.presentToast('Failed to publish workflow', ToastType.Error);
    //       return of(error);
    //     }),
    //   )
    //   .subscribe();
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  unpublishWorkflow(workflowId: number): Subscription {
    return this.http
      .putV2<void>(`api/companies/my/workflows/${workflowId}/unpublish`, {})
      .pipe(
        tap(() => {
          this.portalService.presentToast('Workflow unpublished', ToastType.Success);
          this.requestWorkflowUpdate(workflowId);
          this.store.dispatch(
            new PatchWorkflow(
              workflowId,
              patch({
                status: WorkflowStatus.Draft,
              }),
            ),
          );
        }),
        catchError((error) => {
          this.portalService.presentToast('Failed to unpublish workflow', ToastType.Error);
          return of(error);
        }),
      )
      .subscribe();
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  changeAccessSettings(workflowId: number /* access: WorkflowAccess */): void {
    this.requestWorkflowUpdate(workflowId);
    this.portalService.presentToast('Workflow access settings changed', ToastType.Success);

    // We use legacy functionality for sharing workflows, so this is a placeholder for future implementation
    // TODO (Oleksandr D.): Rewrite workflow share modal

    // return this.http
    //   .patchV2(`api/companies/my/workflows/${workflowId}/access`, {
    //     type: access.type,
    //     teamIds: access.teamsWithAccess,
    //   })
    //   .pipe(
    //     tap(() => {
    //       this.portalService.presentToast('Workflow access settings changed', ToastType.Success);
    //       this.requestWorkflowUpdate(workflowId);
    //       this.store.dispatch(new PatchWorkflow(workflowId, patch({ access })));
    //     }),
    //     catchError((error) => {
    //       this.portalService.presentToast('Failed to change workflow access settings', ToastType.Error);
    //       return of(error);
    //     }),
    //   )
    //   .subscribe();
  }

  @ccPreventDuplicateSubscriptions('first-wins')
  deleteDraftWorkflow(workflowId: number): Subscription {
    return this.http
      .deleteV2(`api/companies/my/workflows/${workflowId}`)
      .pipe(
        tap(() => {
          this.portalService.presentToast('Workflow deleted', ToastType.Success);
          this.store.dispatch(new RemoveWorkflow(workflowId));
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          const errorCodes: string[] =
            errorResponse?.error?.errors?.map((error: { errorCode: string }) => error?.errorCode) || [];

          if (errorCodes.includes(WorkflowErrorCode.InUse)) {
            this.portalService.presentToast('Workflow cannot be deleted because it is in use', ToastType.Error);
            return;
          }

          this.portalService.presentToast('Failed to delete workflow', ToastType.Error);
          return of(errorResponse);
        }),
      )
      .subscribe();
  }

  @ccPreventDuplicateSubscriptions('last-wins')
  private requestWorkflowUpdate(workflowId: number): Subscription {
    return this.getWorkflow(workflowId).subscribe((workflow) => {
      this.store.dispatch(
        new PatchWorkflow(
          workflowId,
          patch({
            // BE does not return version in full workflow response, so for now we keep the old one.
            // TODO (Oleksandr D.): fix this to use version from BE
            ...R.dissoc('version', workflow),
          }),
        ),
      );
      this._workflowUpdated$.next(workflow);
    });
  }
}
