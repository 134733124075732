<div
  [style.background]="backgroundColor"
  [style.color]="foregroundColor"
  class="workflow-status-chip"
>
  @switch (status) {
    @case (WorkflowStatus.Published) {
      PUBLISHED
    }
    @case (WorkflowStatus.Draft) {
      DRAFT
    }
  }
</div>
