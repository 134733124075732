<td
  ccTd
  ccVerticalAlignment="center"
  ccHorizontalAlignment="start"
>
  <div class="workflow-details-cell">
    <div class="workflow-details-cell__preview">
      <img
        [src]="workflow().previewImage"
        [alt]="workflow().name"
      />
    </div>

    <div class="workflow-details-cell__main">
      <span class="workflow-details-cell__main-name">
        {{ workflow().name }}
      </span>

      <span class="workflow-details-cell__main-version">
        <span class="workflow-details-cell__main-version-label">Revision:&nbsp;</span>
        <code class="workflow-details-cell__main-version-value">v{{ workflow().version }}</code>
      </span>
    </div>
  </div>
</td>

<td
  ccTd
  ccVerticalAlignment="center"
  ccHorizontalAlignment="start"
>
  <div class="workflow-type-cell">
    @switch (workflow().type) {
      @case (WorkflowType.Product) {
        Product
      }
      @case (WorkflowType.Company) {
        Company
      }
    }
  </div>
</td>

@if (showStatusColumn()) {
  <td
    ccTd
    ccVerticalAlignment="center"
    ccHorizontalAlignment="start"
  >
    <div class="workflow-status-cell">
      <cc-workflow-status-chip [status]="workflow().status" />
    </div>
  </td>
}

@if (showActionsColumn()) {
  <td
    ccTd
    ccVerticalAlignment="center"
    ccHorizontalAlignment="start"
  >
    <div class="workflow-action-cell">
      <div #dropdownTrigger>
        <app-button
          [active]="dropdownVisible"
          (click)="$event.stopPropagation(); dropdownVisible = !dropdownVisible"
          ccTooltip="More actions"
          [tooltipAlignment]="TooltipAlignment.End"
          [type]="ButtonType.Ghost"
          [size]="ButtonSize.Small"
        >
          <i
            class="icon-more"
            slot="ghost-icon"
          ></i>
        </app-button>
      </div>

      <ng-template
        (detach)="dropdownVisible = false"
        (overlayOutsideClick)="dropdownVisible = getOverlayVisibilityAfterOutsideClick($event, dropdownTrigger)"
        [cdkConnectedOverlayOpen]="dropdownVisible"
        [cdkConnectedOverlayOrigin]="dropdownTrigger"
        [cdkConnectedOverlayPositions]="dropdownPositionStrategy"
        cdkConnectedOverlay
      >
        <cc-dropdown width="160px">
          @if (workflow().status === WorkflowStatus.Published) {
            <cc-dropdown-action (click)="share(); dropdownVisible = false">
              <ng-container slot="title"> Share </ng-container>
            </cc-dropdown-action>

            <cc-dropdown-action (click)="unpublish(); dropdownVisible = false">
              <ng-container slot="title"> Unpublish </ng-container>
            </cc-dropdown-action>
          }

          @if (workflow().status === WorkflowStatus.Draft) {
            <cc-dropdown-action [routerLink]="['/', 'workflows', 'edit', workflow().id]">
              <ng-container slot="title"> Edit </ng-container>
            </cc-dropdown-action>

            <cc-dropdown-action (click)="publishDraftWorkflow(); dropdownVisible = false">
              <ng-container slot="title"> Publish </ng-container>
            </cc-dropdown-action>

            <cc-dropdown-divider />

            <cc-dropdown-action (click)="deleteDraftWorkflow(); dropdownVisible = false">
              <ng-container slot="title"> Delete </ng-container>
            </cc-dropdown-action>
          }
        </cc-dropdown>
      </ng-template>
    </div>
  </td>
}
