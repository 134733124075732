import { EMPTY_PAGING, PagingOrder, type OffsetPagingWrapper, type SortingOptions } from '@clover/core/helpers/paging';

import type { WorkflowsSortingProperty } from './workflows.service';

export interface Workflow {
  id: number;
  name: string;
  description: string;
  version: number;
  graphJSON: string;
  type: WorkflowType;
  status: WorkflowStatus;
  previewImage: string;
  folderId: number;
  access: WorkflowAccess;
}

export interface WorkflowDetails {
  id: number;
  name: string;
  description: string;
  version: number;
  type: WorkflowType;
  status: WorkflowStatus;
  folderId: number;
  access: WorkflowAccess;
  modifiedAt: string;
  createdAt: string;
  createdBy: WorkflowAuthor;
}

export type WorkflowPreview = Pick<Workflow, 'id' | 'name' | 'version' | 'type' | 'status' | 'previewImage' | 'access'>;

export interface WorkflowAccess {
  type: WorkflowAccessType;
  teams: WorkflowTeam[];
}

export interface WorkflowTeam {
  id: number;
  name?: string;
}

export interface WorkflowAuthor {
  id: number;
  firstName: string;
  lastName: string;
  avatarUrl: string;
  title: string;
}

export enum WorkflowType {
  Company = 'Company',
  Product = 'Product',
}

export enum WorkflowStatus {
  Published = 'Published',
  Draft = 'Draft',
}

export enum WorkflowAccessType {
  All = 'All',
  Teams = 'Teams',
}

export enum FilterableWorkflowStatus {
  All = 'All',
  Published = 'Published',
  Draft = 'Draft',
}

export interface WorkflowsStateModel {
  workflows: OffsetPagingWrapper<WorkflowPreview>;
  loadingStatus: 'void' | 'loading' | 'loaded' | 'error' | 'loading-next';
  query: string;
  status: FilterableWorkflowStatus;
  sorting: SortingOptions<WorkflowsSortingProperty>;
}

export const defaultWorkflowsState: WorkflowsStateModel = {
  workflows: EMPTY_PAGING,
  loadingStatus: 'void',
  query: '',
  status: FilterableWorkflowStatus.All,
  sorting: {
    orderBy: 'name',
    order: PagingOrder.Ascending,
  },
};
